<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuovo cliente</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <form @submit.prevent="creaAnagrafica()">
                    <ion-list class="fields">
                        <!-- Ragione sociale -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Ragione sociale
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="anagrafica.ragione_sociale"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ragione sociale -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Indirizzo
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="anagrafica.indirzzo"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Città -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Città
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="anagrafica.citta"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Partita IVA -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Partita iva
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="anagrafica.partita_iva"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Cellulare -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Cellulare
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="anagrafica.cellulare"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <div class="action">
                            <button type="submit" class="btn_crea_cliente" :disabled="isLoading">
                                Crea cliente
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonText,
    IonInput,
    /* IonSelect,
    IonSelectOption, */
    IonIcon,
    actionSheetController,
} from "@ionic/vue";
import { arrowBackOutline, close, trash } from "ionicons/icons";

import { defineComponent, ref, reactive } from "vue";

import { openToast } from "@/services/toast";

import apiClienti from "@/services/clienti";

export default defineComponent({
    name: "ModalNuovaAnagrafica",
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonText,
        IonInput,
        /* IonSelect,
        IonSelectOption, */
        IonIcon,
    },
    setup(props, context) {
        const appuntamento = { ...props.data };

        const anagrafica = reactive({
            ragione_sociale: null,
            indirzzo: null,
            citta: null,
            partita_iva: null,
            cellulare: null,
        });

        const successResponse = ref(false);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Create new ordine
         */
        const isLoading = ref(false);
        async function creaAnagrafica() {
            isLoading.value = true;

            if (!anagrafica.ragione_sociale) {
                isLoading.value = false;
                openToast("Non puoi creare l'anagrafica senza la ragione sociale", "toast_danger");
                return;
            }

            const data = new FormData();
            data.append("customers_type", "1"); //Customer
            data.append("customers_group", "2"); //Azienda
            if (anagrafica.ragione_sociale && anagrafica.ragione_sociale != "") {
                data.append("customers_company", anagrafica.ragione_sociale);
            }
            if (anagrafica.partita_iva && anagrafica.partita_iva != "") {
                data.append("customers_vat_number", anagrafica.partita_iva);
            }
            if (anagrafica.citta && anagrafica.citta != "") {
                data.append("customers_city", anagrafica.citta);
            }
            if (anagrafica.cellulare && anagrafica.cellulare != "") {
                data.append("customers_mobile", anagrafica.cellulare);
            }

            // Display the values
            /* for (const value of data.entries()) {
                console.log(value[0] + ", " + value[1]);
            }
            return; */

            try {
                const res = await apiClienti.saveCliente(data);

                if (res.status === 200 && res.data.status === 0) {
                    successResponse.value = true;
                    closeModalOnSubmit(successResponse, res.data.data[0]);
                } else {
                    successResponse.value = false;
                    openToast(res.message, "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la creazione dell'ordine", "toast_danger");
            } finally {
                isLoading.value = false;
            }
        }

        return {
            closeModal,
            arrowBackOutline,
            close,
            trash,
            //appuntamento data
            appuntamento,
            //ordine
            creaAnagrafica,
            anagrafica,
            //loading
            isLoading,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f2f2f2;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_crea_cliente {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
}
.btn_crea_cliente:disabled {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
    opacity: 0.5;
}
ion-button {
    --color: #ffffff;
}
</style>
