
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    IonInput,
    modalController,
    menuController,
    IonButtons,
} from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, onMounted, computed } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { openToast } from "@/services/toast";
import apiClienti from "@/services/clienti";

import ClienteDetail from "@/components/clienti/ClienteDetail.vue";
import ModalNuovaAnagrafica from "@/components/clienti/ModalNuovaAnagrafica.vue";

export default {
    name: "Clienti",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        IonInput,
    },
    setup() {
        const loading = ref(false);
        const loadingFornitori = ref(false);
        const loadingContatti = ref(false);
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).users_id;

        const openMenu = () => {
            menuController.open("app-menu");
        };

        const modeSwitcher = [
            {
                mode_label: "Clienti",
                mode_id: "clienti",
            },
            {
                mode_label: "Fornitori",
                mode_id: "fornitori",
            },
            {
                mode_label: "Contatti",
                mode_id: "contatti",
            },
        ];

        const clienti = ref([]);
        const showClienti = ref(true);
        const searchQuery = ref("");
        const searchedCustomers = computed(() => {
            const term = searchQuery.value.toLowerCase().replace(/ /g, "");

            return clienti.value.filter((cliente) => {
                const company = cliente.customers_company ? cliente.customers_company : "";
                const name = cliente.customers_name ? cliente.customers_name : "";
                const last_name = cliente.customers_last_name ? cliente.customers_last_name : "";

                const allFields = `${company} ${name} ${last_name}`.toLowerCase().replace(/ /g, "");

                return allFields.includes(term);
            });
        });

        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            } else if (cliente.customers_name && !cliente.customers_last_name) {
                return `${cliente.customers_name}`;
            }
            return `${cliente.customers_name} ${cliente.customers_last_name}`;
        };

        const indirizzoAnagrafica = (cliente) => {
            const city = cliente.customers_city ? cliente.customers_city : "";
            const province = cliente.customers_province ? "(" + cliente.customers_province + ")" : "";
            return `${city} ${province}`;
        };

        async function openNewCustomer() {
            const modal = await modalController.create({
                component: ModalNuovaAnagrafica,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //console.log(detail.data);
                    clienti.value.unshift(detail.data);
                    openToast("Anagrafica creata con successo", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * Passing customer objectto ClienteDetail page
         */
        async function openDetailModal(customer) {
            //console.log(customer);
            const modal = await modalController.create({
                component: ClienteDetail,
                componentProps: {
                    data: customer,
                },
            });
            return modal.present();
        }

        /**
         * Get all clienti
         */
        async function loadClienti() {
            loading.value = true;
            try {
                const res = await apiClienti.getCustomers();
                if (res.status === 0) {
                    clienti.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei clienti", "toast_danger");
                }
            } catch (error) {
                clienti.value = [];
                openToast("Errore durante la richiesta dei clienti", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /**
         * ! FORNITORI
         */
        const showFornitori = ref(false);
        const fornitori = ref([]);
        async function loadFornitori() {
            loadingFornitori.value = true;
            try {
                const res = await apiClienti.getFornitori();
                if (res.status === 0) {
                    fornitori.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei fornitori", "toast_danger");
                }
            } catch (error) {
                fornitori.value = [];
                openToast("Errore durante la richiesta dei fornitori", "toast_danger");
            } finally {
                loadingFornitori.value = false;
            }
        }

        //Ricerca fornitori
        const searchQueryFornitori = ref("");
        const searchedFornitori = computed(() => {
            const term = searchQueryFornitori.value.toLowerCase().replace(/ /g, "");

            return fornitori.value.filter((fornitore) => {
                const company = fornitore.customers_company ? fornitore.customers_company : "";
                const name = fornitore.customers_name ? fornitore.customers_name : "";
                const last_name = fornitore.customers_last_name ? fornitore.customers_last_name : "";

                const allFields = `${company} ${name} ${last_name}`.toLowerCase().replace(/ /g, "");

                return allFields.includes(term);
            });
        });

        /**
         * ! CONTATTI
         */
        const showContatti = ref(false);
        const contatti = ref([]);
        async function loadContatti() {
            loadingContatti.value = true;
            try {
                const res = await apiClienti.getContatti();
                if (res.status === 0) {
                    contatti.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei contatti", "toast_danger");
                }
            } catch (error) {
                contatti.value = [];
                openToast("Errore durante la richiesta dei contatti", "toast_danger");
            } finally {
                loadingContatti.value = false;
            }
        }

        //Ricerca contatti
        const searchQueryContatti = ref("");
        const searchedContatti = computed(() => {
            const term = searchQueryContatti.value.toLowerCase().replace(/ /g, "");

            return contatti.value.filter((contatto) => {
                const company = contatto.customers_company ? contatto.customers_company : "";
                const name = contatto.customers_contacts_name ? contatto.customers_contacts_name : "";
                const last_name = contatto.customers_contacts_last_name ? contatto.customers_contacts_last_name : "";

                const allFields = `${company} ${name} ${last_name}`.toLowerCase().replace(/ /g, "");

                return allFields.includes(term);
            });
        });

        const riferimentoContatto = (contatto) => {
            /* if (contatto.customers_company) {
                return contatto.customers_company;
            } else  */ if (
                contatto.customers_contacts_name &&
                !contatto.customers_contacts_last_name
            ) {
                return `${contatto.customers_contacts_name}`;
            }
            return `${contatto.customers_contacts_name} ${contatto.customers_contacts_last_name}`;
        };
        /**
         * ! SWITCHER ANAGRAFICHE
         */
        const selectedAnagraficaType = ref("clienti"); //Documenti generali di default
        function setActiveAnagrafica(type) {
            selectedAnagraficaType.value = type;
            if (type === "clienti") {
                showClienti.value = true;
                showFornitori.value = false;
                showContatti.value = false;
            } else if (type === "fornitori") {
                showFornitori.value = true;
                showClienti.value = false;
                showContatti.value = false;
            } else {
                showContatti.value = true;
                showFornitori.value = false;
                showClienti.value = false;
            }
        }

        onMounted(() => {
            //GET CLIENTI DATA
            loadClienti();
            loadFornitori();
            loadContatti();
        });

        return {
            loading,
            add,
            refresh,
            openDetailModal,
            loadClienti,
            clienti,
            showClienti,
            searchQuery,
            searchedCustomers,
            riferimentoCliente,
            indirizzoAnagrafica,
            menu,
            openMenu,
            openNewCustomer,
            //Fornitori
            loadingFornitori,
            fornitori,
            showFornitori,
            searchQueryFornitori,
            searchedFornitori,
            //Contatti
            loadingContatti,
            contatti,
            showContatti,
            riferimentoContatto,
            searchQueryContatti,
            searchedContatti,
            //Switcher anagrafiche
            setActiveAnagrafica,
            modeSwitcher,
            selectedAnagraficaType,
        };
    },
};
